<gipi-abstract-form id="dialogReceivementCard"
                    name="dialogReceivementCard"
                    [dialog]="true">

    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogReceivementCard">
        Receber com cartão
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px"
         (keydown.enter)="loading ? null : confirm()"
         style="height: 65vh;">
        <div fxLayout="row"
             fxLayoutAlign="start"
             fxLayoutGap="16px"
             fxLayout.lt-sm="column">
            <itss-input label="Tipo de cobrança"
                        [value]="receivementCard.chargeType.description"
                        disabled="true"
                        fxFlex="50"
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-select-entity-paged label="Administradora de cartão"
                                      required="true"
                                      property="description"
                                      [(model)]="receivementCard.cardAdministrator"
                                      [nextBatchFn]="cardAdministratorFindByValueFn"
                                      fxFlex
                                      fxFlex.lt-md="100">
            </itss-select-entity-paged>
        </div>
        <div fxLayout="row"
             fxLayoutAlign="start"
             fxLayoutGap="16px"
             fxLayout.lt-sm="column">
            <itss-select-entity-paged label="Bandeira do cartão"
                                      required="true"
                                      property="description"
                                      [(model)]="receivementCard.flagCard"
                                      [nextBatchFn]="flagCardFindByValueFn"
                                      fxFlex="50"
                                      fxFlex.lt-md="100">
            </itss-select-entity-paged>
            <itss-input label="Qtd. de parcelas"
                        [(ngModel)]="quantityInstallment"
                        *ngIf="receivementCard.chargeType.type === 'CREDIT_CARD'"
                        mask="0*"
                        required="true"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input-currency label="Valor"
                                 [(ngModel)]="receivementCard.amountCard"
                                 required="true"
                                 [options]="optionAmountCard"
                                 fxFlex
                                 fxFlex.lt-md="100">
            </itss-input-currency>
        </div>
        <div fxLayout="row"
             fxLayoutAlign="end end"
             fxLayoutGap="16px"
             fxLayout.lt-sm="column">
            <gipi-button label="Adicionar"
                         gipi-primary
                         id="btnAddReceivementCard"
                         (click)="addReceivementCard()"
                         fxFlex="20"
                         fxFlex.lt-md="100">
            </gipi-button>
        </div>
        <itss-table [paginator]="false"
                    [columns]="createTableColumns()"
                    [paginatorPageSize]="receivementCardList.length"
                    [data]="receivementCardList"
                    [backendPagination]="false"
                    [loading]="loading">
        </itss-table>
    </div>
    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Confirmar"
                     gipi-primary
                     id="btnSave"
                     [disabled]="loading"
                     [loading]="loading"
                     (click)="confirm()"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     fxFlex="20"
                     (click)="close(false)"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>

<ng-template #actions
             let-entity>
    <itss-button tooltip="Excluir"
                 svgIcon="trash"
                 color="warn"
                 type="icon"
                 [disabled]="loading"
                 (click)="removeReceivementCard(entity)">
    </itss-button>
</ng-template>
