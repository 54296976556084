import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { FinancialCardAdministrator } from '@gipi-financial/card-administrator/models/card-administrador.model';
import { FinancialReceivementCard } from '@gipi-financial/card-administrator/models/receivement-card.model';
import { FinancialCardAdministratorService } from '@gipi-financial/card-administrator/services/card-administrator.service';
import { FinancialFlagCard } from '@gipi-financial/flag-card/models/flag-card.model';
import { FinancialFlagCardService } from '@gipi-financial/flag-card/services/flag-card.service';
import { FinancialReceivement } from '@gipi-financial/receivement/models/receivement.model';
import { FinancialReceivementService } from '@gipi-financial/receivement/services/receivement.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, APP_MESSAGES, ArrayUtil, CurrencyUtil, DateUtil, INJECTOR, NumberUtil, ObjectUtil, PageDTO, TableColumnBuilder, TableColumnDTO } from '@gipisistemas/ng-core';

export interface ReceivementCardData {
    receivement: FinancialReceivement;
    installmentList: FinancialBillInstallment[];
    isCashier: boolean;
    useClientCredit: boolean;
}

@Component({
    templateUrl: './receivement-card-dialog.component.html',
    styles: [`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    `]
})
export class ReceivementCardDialogComponent extends AbstractComponent implements OnInit {

    @ViewChild('actions', { static: true }) actions: TemplateRef<any>;

    receivement: FinancialReceivement;

    billInstallmentList: FinancialBillInstallment[] = [];

    receivementCardList: FinancialReceivementCard[] = [];

    receivementCard: FinancialReceivementCard = new FinancialReceivementCard();

    receivementAmount: number = 0;

    amountAdded: number = 0;

    amountRemaining: number = 0;

    quantityInstallment: number = 1;

    optionAmountCard: {} = {};

    cardAdministratorFindByValueFn = async (value: string, page: number) => {
        const result: PageDTO<FinancialCardAdministrator> = await this._cardAdministratorService.findByValue(value, page, 10).toPromise();
        return result;
    };

    flagCardFindByValueFn = async (value: string, page: number) => {
        const result: PageDTO<FinancialFlagCard> = await this._flagCardService.findByValue(value, page, 10).toPromise();
        return result;
    };

    constructor(
        public dialogRef: MatDialogRef<ReceivementCardDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ReceivementCardData = { receivement: null, installmentList: [], isCashier: false, useClientCredit: false },
        private _receivementService: FinancialReceivementService,
        private _cardAdministratorService: FinancialCardAdministratorService,
        private _flagCardService: FinancialFlagCardService,
        messageService: CustomMessageService,
        router: Router,
        activatedRoute: ActivatedRoute
    ) {
        super(messageService, router, activatedRoute);
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit();
        this.receivementCard.chargeType = this.data.receivement.chargeType;
        this.receivementCard.bankAccount = this.data.receivement.bankAccount;
        this.receivement = this.data.receivement;
        this.receivement.amountNet = this.receivement.amountReceivable;
        this.receivementAmount = (this.receivement.amountReceived ? this.receivement.amountReceived : this.receivement.amountReceivable);
        this.billInstallmentList = this.data.installmentList;
        this.newReceivementCard();
    }

    addReceivementCard(): void {
        try {
            this.validateAddNewReceivementCard();

            const idLaunchReference: number = this.getIdLaunchReference();
            const amountPortion: number = (this.receivementCard.amountCard / this.quantityInstallment);
            const lNow: Date = new Date();
            const lReceivementCardList: FinancialReceivementCard[] = [];

            let daysAdd: number = 0;
            if (this.receivementCard.chargeType.type === 'CREDIT_CARD') {
                daysAdd = this.receivementCard.cardAdministrator.dayCreditReceipt;
            } else {
                daysAdd = this.receivementCard.cardAdministrator.dayDebitReceipt;
            }

            for (let i: number = 0; i < this.quantityInstallment; i++) {
                const lReceivementCard: FinancialReceivementCard = ObjectUtil.clone(this.receivementCard);

                lReceivementCard.amountCard = Number(amountPortion.toFixed(2));
                lReceivementCard.numberInstallment = i + 1;
                lReceivementCard.dueDate = new Date(lNow.getFullYear(), lNow.getMonth() + i, lNow.getDate() + daysAdd);
                lReceivementCard.idLauchReference = idLaunchReference;

                lReceivementCardList.push(lReceivementCard);
            }

            const amountAdded: number = Number(lReceivementCardList.reduce((sum, rc) => sum += rc.amountCard, 0));
            const amountDifference: number = Number(amountAdded.toFixed(2)) - this.receivementCard.amountCard;
            if (amountDifference > 0) {
                lReceivementCardList[0].amountCard -= amountDifference;
            } else if (amountDifference < 0) {
                const difference: number = (amountDifference * -1);
                lReceivementCardList[0].amountCard += difference;
            }

            this.receivementCardList = this.receivementCardList.concat(...lReceivementCardList);
            this.newReceivementCard();
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    removeReceivementCard(receivementCard: FinancialReceivementCard): void {
        setTimeout(() => {
            const lReceivementCardList: FinancialReceivementCard[] = ArrayUtil.clone(this.receivementCardList);
            const indexReceivementCard: number = lReceivementCardList.findIndex(rc => (rc.idLauchReference === receivementCard.idLauchReference) && (rc.numberInstallment === receivementCard.numberInstallment));
            lReceivementCardList.splice(indexReceivementCard, 1);

            this.refactorInstallments(lReceivementCardList, receivementCard);

            this.receivementCardList = [];
            this.receivementCardList = lReceivementCardList;

            this.calculateAmountReceivable();
        });
    }

    refactorInstallments(receivementCardList: FinancialReceivementCard[], receivementCard: FinancialReceivementCard): void {
        const lReceivementCardList: FinancialReceivementCard[] = receivementCardList.filter(rc => rc.idLauchReference === receivementCard.idLauchReference);
        const date: Date = new Date();

        for (let i: number = 0; i < lReceivementCardList.length; i++) {
            const lReceivementCard: FinancialReceivementCard = lReceivementCardList[i];
            let daysAdd: number = 0;
            if (lReceivementCard.chargeType.type === 'CREDIT_CARD') {
                daysAdd = lReceivementCard.cardAdministrator.dayCreditReceipt;
            } else {
                daysAdd = lReceivementCard.cardAdministrator.dayDebitReceipt;
            }

            lReceivementCard.numberInstallment = i + 1;
            lReceivementCard.dueDate = new Date(date.getFullYear(), date.getMonth() + i, date.getDate() + daysAdd);
        }
    }

    newReceivementCard(): void {
        setTimeout(() => {
            this.receivementCard = new FinancialReceivementCard();
            this.receivementCard.chargeType = this.data.receivement.chargeType;
            this.receivementCard.cardAdministrator = new FinancialCardAdministrator();
            this.receivementCard.flagCard = new FinancialFlagCard();
            this.receivementCard.issuanceDate = new Date();
            this.receivementCard.dueDate = new Date();
            this.receivementCard.status = 'RECEIVABLE';
            this.receivementCard.bankAccount = this.data.receivement.bankAccount;
            this.quantityInstallment = 1;
            this.calculateAmountReceivable();
        });
    }

    calculateAmountReceivable(): void {
        this.amountAdded = this.receivementCardList.reduce((sum, rc) => sum += rc.amountCard, 0);
        this.amountRemaining = this.receivementAmount - this.amountAdded;
        this.receivementCard.amountCard = this.amountRemaining;
        this.setOptionsAmountCard();
    }

    async confirm(): Promise<void> {
        try {
            this.validateConfirm();
            this.loading = true;

            await this._receivementService.receive(this.receivement, this.billInstallmentList, this.receivementCardList, this.data.useClientCredit).toPromise().then(() => {
                this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                this.close(true);
            }, error => {
                this.loading = false;
                this.handleError(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    validateConfirm(): void {
        const amountAdded: number = Number(this.receivementCardList.reduce((sum, rc) => sum += rc.amountCard, 0).toFixed(2));

        if (amountAdded < this.receivementAmount) {
            const amountReceivable: number = Number((this.receivementAmount - amountAdded).toFixed(2));
            throw new Error(`Ainda falta lançar R$ ${CurrencyUtil.transform(amountReceivable, '1.2-2')} para corresponder o valor total do recebimento`);
        }
        if (amountAdded > this.receivementAmount) {
            throw new Error(`O valor lançado R$ ${CurrencyUtil.transform(Number((amountAdded).toFixed(2)), '1.2-2')} está ultrapassando o valor total do recebimento`);
        }
        if (ArrayUtil.isEmpty(this.receivementCardList)) {
            throw new Error('É necessário adicionar no mínimo uma parcela, para salvar o recebimento');
        }
    }

    validateAddNewReceivementCard(): void {
        if (ObjectUtil.isNewModel(this.receivementCard.cardAdministrator) || ObjectUtil.isNull(this.receivementCard.cardAdministrator)) {
            throw new Error('Campo administradora de cartão é obrigatório e não foi informado');
        }
        if (ObjectUtil.isNewModel(this.receivementCard.flagCard) || ObjectUtil.isNull(this.receivementCard.flagCard)) {
            throw new Error('Campo bandeira do cartão é obrigatório e não foi informado');
        }
        if (!NumberUtil.isPositive(this.quantityInstallment)) {
            throw new Error('Campo quantidade de parcelas é obrigatório e não foi informado');
        }
        const amountAdded: number = this.receivementCardList.reduce((sum, rc) => sum += rc.amountCard, 0);
        if (amountAdded === this.receivementAmount) {
            throw new Error(`O valor lançado R$ ${(amountAdded).toFixed(2)} já corresponde ao valor total do recebimento`);
        }
        if ((this.receivementCard.amountCard + amountAdded) > this.receivementAmount) {
            throw new Error(`O valor que você está tentando lançar R$ ${(amountAdded).toFixed(2)} ultprassa o valor total do recebimento`);
        }
        if (!NumberUtil.isPositive(this.receivementCard.amountCard)) {
            throw new Error('Campo valor é obrigatório e não foi informado');
        }
        if (!DateUtil.isValid(this.receivementCard.issuanceDate)) {
            throw new Error('Campo data da emissão é obrigatório e não foi informado');
        }
        if ((this.receivementCard.amountCard / this.quantityInstallment) < 1) {
            throw new Error('Não é possível gerar parcelas com valor abaixo de R$ 1,00');
        }
    }

    createTableColumns(): TableColumnDTO[] {
        return [
            TableColumnBuilder.instance()
                .property('numberInstallment')
                .name('N°')
                .value((obj: FinancialReceivementCard) => ('00' + obj.numberInstallment).slice(-2))
                .width(5)
                .align('center center')
                .build(),
            TableColumnBuilder.instance()
                .property('issuanceDate')
                .name('Emissão')
                .value((obj: FinancialReceivementCard) => DateUtil.format(obj.issuanceDate, DateUtil.DATE_FORMAT))
                .width(8)
                .align('center center')
                .build(),
            TableColumnBuilder.instance()
                .property('dueDate')
                .name('Vencimento')
                .value((obj: FinancialReceivementCard) => DateUtil.format(obj.dueDate, DateUtil.DATE_FORMAT))
                .width(8)
                .align('center center')
                .build(),
            TableColumnBuilder.instance()
                .property('cardAdministrator')
                .name('Administradora de cartão')
                .value((obj: FinancialReceivementCard) => obj.cardAdministrator.description)
                .marginLeft(15)
                .build(),
            TableColumnBuilder.instance()
                .property('flagCard')
                .name('Bandeira do cartão')
                .value((obj: FinancialReceivementCard) => obj.flagCard.description)
                .build(),
            TableColumnBuilder.instance()
                .property('amountCard')
                .name('Valor')
                .value((obj: FinancialReceivementCard) => CurrencyUtil.transform(obj.amountCard, '1.2-2'))
                .align('center center')
                .width(10)
                .build(),
            TableColumnBuilder.instance()
                .property('actions')
                .name('Ações')
                .template(this.actions)
                .align('center center')
                .width(10)
                .build(),
        ];
    }

    private getIdLaunchReference(): number {
        const IdLaunchReferenceList: number[] = [];

        this.receivementCardList.forEach((receivementCard) => {
            IdLaunchReferenceList.push(receivementCard.idLauchReference);
        });

        if (IdLaunchReferenceList.length <= 0) {
            return 1;
        } else {
            return Math.max(...IdLaunchReferenceList) + 1;
        }
    }

    setOptionsAmountCard(): void {
        if (NumberUtil.isPositive(this.amountRemaining)) {
            this.optionAmountCard = { allowNegative: false, min: 0, max: this.amountRemaining };
        } else {
            this.optionAmountCard = { allowNegative: false, min: 0 };
        }
    }

    close(isSaved: boolean): void {
        this.dialogRef.close(isSaved);
    }

}
