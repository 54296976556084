import * as tslib_1 from "tslib";
import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from '@gipi-environment/environment';
import { GeneralIcons, MenuIcons, NewIcons } from '@gipi-assets/assets.enum';
import { FinancialCompanyService } from '@gipi-financial/company/services/company.service';
import { ConfigurationDialogComponent } from '@gipi-financial/configuration/components/configuration-dialog/configuration-dialog.component';
import { FinancialConfigurationService } from '@gipi-financial/configuration/services/configuration.service';
import { OAuthUserService } from '@gipi-financial/user/services/user.service';
import { GlobalFileService } from '@gipi-global/file/services/file.service';
import { CustomerNotificationConfigDialogComponent } from '@gipi-notification/customer-notification-config/components/customer-notification-config-dialog/customer-notification-config-dialog.component';
import { NotificationInvoiceIntegrationsService } from '@gipi-notification/invoice-integrations/services/invoice-integrations.service';
import { GIPINoveltiesDialogComponent } from '@gipi-shared/components/novelties-dialog/novelties-dialog.component';
import { UserInformationDialogComponent } from '@gipi-shared/components/user-information-dialog/user-information-dialog.component';
import { GIPIBaseAbstractFilterModel } from '@gipi-shared/models/base-abstract-filter.model';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { SessionStorageService } from '@gipi-shared/services/session-storage.service';
import { AbstractComponent, ArrayUtil, DialogService, NavService, ObjectUtil, StringUtil, SvgRegisterService } from '@gipisistemas/ng-core';
export class AppComponent extends AbstractComponent {
    constructor(messageService, router, activatedRoute, _authenticationService, _userService, _companyService, _configurationService, _invoiceIntegrationsService, _dialogService, _navService, _svgRegisterService, _fileService, _sessionStorageService) {
        super(messageService, router, activatedRoute);
        this.messageService = messageService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this._authenticationService = _authenticationService;
        this._userService = _userService;
        this._companyService = _companyService;
        this._configurationService = _configurationService;
        this._invoiceIntegrationsService = _invoiceIntegrationsService;
        this._dialogService = _dialogService;
        this._navService = _navService;
        this._svgRegisterService = _svgRegisterService;
        this._fileService = _fileService;
        this._sessionStorageService = _sessionStorageService;
        this.companyList = [];
        this.userPhotoSrc = '';
        this.hasNotification$ = new BehaviorSubject(false);
        this.isLoad = false;
        this.widgetChat = false;
        this.compnayFn = (obj) => (!ObjectUtil.isNull(obj) && !ObjectUtil.isNull(obj.person)) ? obj.person.name : '';
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this._registerSvgs();
            this.router.events.pipe(filter(e => e instanceof NavigationEnd && e.url.includes('login'))).subscribe(() => this.reloadCurrentRoute());
            const token = this._authenticationService.tokenValue;
            if (this._authenticationService.isValidToken(token)) {
                this.loading = true;
                this._loadScriptHotjar();
                this._setConfigurationInLocalStorage();
                this._getCompany(token);
                this._getUserLogged(token);
                this.getNotifications();
                this.loading = false;
            }
        });
    }
    ngAfterViewInit() {
        setTimeout(() => {
            this._showWidgetChat();
        }, 1000);
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        this.hasNotification$.next(false);
        this.hasNotification$.complete();
    }
    _loadScriptHotjar() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const hotjarId = environment.hotjarId;
            if (hotjarId > 0) {
                let scriptElement = document.createElement('script');
                scriptElement.textContent = `
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:${hotjarId},hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `;
                document.getElementsByTagName('head')[0].appendChild(scriptElement);
            }
        });
    }
    _showWidgetChat() {
        const btnWidgetChat = document.getElementById('widgetChat');
        if (!ObjectUtil.isNull(btnWidgetChat)) {
            btnWidgetChat.style.display = 'flex';
        }
    }
    _getUserLogged(token) {
        this._userService.getByUserName(token.user.username).subscribe(user => {
            this.userLogged = ObjectUtil.clone(user);
            this._getUserPhoto();
        }, error => {
            this.handleError(error);
        });
    }
    _getCompany(token) {
        let count = 1;
        const filter = new GIPIBaseAbstractFilterModel();
        filter.pageNumber = 0;
        filter.pageSize = 300;
        filter.researchField = '';
        this._companyService.findAll(filter).subscribe(page => {
            if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                if ((token.user.companyList.length !== page.content.length) && count <= 2) {
                    this._getCompany(token);
                    count++;
                }
                else {
                    this.companyList = ArrayUtil.clone(page.content);
                    const currentCompany = page.content.find(company => company.id === token.user.currentCompany);
                    this.company = ObjectUtil.clone(currentCompany);
                }
                if (!ObjectUtil.isNull(this.company) &&
                    !ObjectUtil.isNull(this.company.person) &&
                    !ObjectUtil.isNull(this.company.person.legalPerson) &&
                    !ObjectUtil.isNull(this.company.person.legalPerson.lineOfBusiness)) {
                    this._sessionStorageService.set('companyLineOfBusiness', this.company.person.legalPerson.lineOfBusiness);
                }
            }
        }, error => {
            this.handleError(error);
        });
    }
    getNotifications() {
        if (ObjectUtil.isNull(this.hasNotification$) ||
            (!ObjectUtil.isNull(this.hasNotification$) && this.hasNotification$.closed)) {
            this.hasNotification$ = new BehaviorSubject(false);
        }
        this._invoiceIntegrationsService.findIfHaveNotificationToReceive().subscribe(hasNotification => {
            this.hasNotification$.next(hasNotification);
        }, error => {
            this.hasNotification$.next(false);
            this.addErrorMessage(error);
            this.loading = false;
        });
    }
    reloadCurrentRoute() {
        const currentUrl = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([currentUrl]));
    }
    toggle() {
        this._navService.toggle();
    }
    isOpened() {
        return this._navService.isOpened;
    }
    _registerSvgs() {
        this._svgRegisterService.registerListSvgIcons(GeneralIcons, '../assets/images/icons');
        this._svgRegisterService.registerListSvgIcons(MenuIcons, '../assets/images/menu');
        this._svgRegisterService.registerListSvgIcons(NewIcons, '../assets/images/new-icons');
    }
    changeCompany(company) {
        try {
            this.loading = true;
            this._authenticationService.changeCompany(company.id).toPromise().then(_ => {
                location.replace('/');
                this.loading = false;
            }).catch(error => {
                this.loading = false;
                this.handleError(error);
            });
        }
        catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }
    get isAuthenticated() {
        if (!ObjectUtil.isNull(this._authenticationService.tokenValueLocalStorage)) {
            return this._authenticationService.token.pipe(map(token => this._authenticationService.isValidToken(token)));
        }
        return of(false);
    }
    // public get firstName(): Observable<string> {
    //     const token: TokenDTO<OAuthUser> = this._authenticationService.tokenValue;
    //     if (ObjectUtil.isNull(token)) {
    //         return of(StringUtil.EMPTY);
    //     }
    //     const firstName: string = token.user.name.split(' ')[0];
    //     const
    //     return this._authenticationService.token.pipe(map(token => !ObjectUtil.isNull(token) ? token.user.name.split(' ')[0] : StringUtil.EMPTY));
    // }
    get firstName() {
        return this._authenticationService.token.pipe(map(token => {
            if (ObjectUtil.isNull(token)) {
                return StringUtil.EMPTY;
            }
            const nameParts = token.user.name.split(' ');
            return ((nameParts.length > 1) && (nameParts[0].length <= 2)) ? `${nameParts[0]} ${nameParts[1]}` : nameParts[0];
        }));
    }
    get username() {
        return this._authenticationService.token.pipe(map(token => !ObjectUtil.isNull(token) ? token.user.name : StringUtil.EMPTY));
    }
    get email() {
        return this._authenticationService.token.pipe(map(token => !ObjectUtil.isNull(token) ? token.user.username : StringUtil.EMPTY));
    }
    get lastAccess() {
        return this._authenticationService.token.pipe(map(token => !ObjectUtil.isNull(token) ? token.user.lastAccess : null));
    }
    get menuList() {
        return this._authenticationService.token.pipe(map(token => !ObjectUtil.isNull(token) ? this._filterMenuItems(token.user.menuList) : []));
    }
    _filterMenuItems(menuList) {
        if (!environment.production) {
            return menuList;
        }
        const hideMenuList = [
            '/financial/registers/products/products',
            '/financial/registers/promotional-schedules/promotional-scheduling',
        ];
        if (!this._isCompanyGIPI) {
            hideMenuList.push('/financial/notifications/invoice-integration');
        }
        return menuList.reduce((filtered, item) => {
            if (!hideMenuList.includes(item.route)) {
                if (item.menuList && item.menuList.length > 0) {
                    item.menuList = this._filterMenuItems(item.menuList);
                }
                filtered.push(item);
            }
            return filtered;
        }, []);
    }
    get hasNovelties() {
        // ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"]
        var date = new Date();
        var weekday = date.getDay();
        return ((weekday) && (weekday === 3));
    }
    get hasNotification() {
        return !ObjectUtil.isNull(this.hasNotification$)
            ? this.hasNotification$.asObservable()
            : of(false);
    }
    get isCompanyGIPI() {
        return of(this._isCompanyGIPI);
    }
    get _isCompanyGIPI() {
        const currentCnpjCompany = ((!ObjectUtil.isNull(this.company) && !ObjectUtil.isNull(this.company.person))
            ? (!ObjectUtil.isNull(this.company.person.legalPerson) ? this.company.person.legalPerson.cnpj : this.company.person.naturalPerson.cpf)
            : null);
        const cnpjCompanyGIPI = environment.cnpjCompany || null;
        return (!StringUtil.isEmpty(cnpjCompanyGIPI) &&
            !StringUtil.isEmpty(currentCnpjCompany) &&
            (cnpjCompanyGIPI === currentCnpjCompany));
    }
    logout() {
        this._authenticationService.logout();
        this.clearSessionStorage();
    }
    openNoveltiesDialog() {
        this._dialogService.open({
            componentOrTemplateRef: GIPINoveltiesDialogComponent,
            width: '55%',
            height: '90%',
            panelClass: 'no-scrolls-dialog'
        });
    }
    openUserInformationDialog() {
        this._dialogService.open({
            componentOrTemplateRef: UserInformationDialogComponent,
            data: ObjectUtil.clone(this.userLogged),
            width: '50%'
        }).afterClosed().toPromise().then((user) => {
            if (!ObjectUtil.isNewModel(user)) {
                this.userLogged.name = user.name;
                this.userLogged.photo = user.photo;
                this._getUserPhoto();
            }
        });
    }
    _getUserPhoto() {
        try {
            if (!ObjectUtil.isNewModel(this.userLogged) && !ObjectUtil.isNull(this.userLogged.photo) && !StringUtil.isEmpty(this.userLogged.photo)) {
                this._fileService.download('authenticator', this.userLogged.photo).subscribe(resp => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(resp.body);
                    fileReader.onloadend = () => this.userPhotoSrc = fileReader.result;
                    this.loading = false;
                }, error => {
                    throw new Error(error);
                });
            }
        }
        catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }
    clearSessionStorage() {
        sessionStorage.clear();
    }
    onMenuItemIntegrationChange() {
        this._dialogService.open({
            componentOrTemplateRef: CustomerNotificationConfigDialogComponent,
            data: null,
            width: '60%',
            height: '65rem'
        });
    }
    onMenuItemConfigurationChange() {
        this._dialogService.open({
            componentOrTemplateRef: ConfigurationDialogComponent,
            data: null,
            width: '60%',
            height: '65rem'
        });
    }
    _setConfigurationInLocalStorage() {
        this._configurationService.findEnabled().subscribe(configuration => this._sessionStorageService.set('configuration', configuration));
    }
}
