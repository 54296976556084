import { GIPIUuid } from '@gipisistemas/ng-core';

export class NotificationNotificationClosingMonthCompanyBillStatementDTO {

    billStatementId: GIPIUuid;

    companyId: GIPIUuid;

    constructor(
        billStatementId?: GIPIUuid,
        companyId?: GIPIUuid
    ) {
        this.billStatementId = billStatementId;
        this.companyId = companyId;
    }

}
